import '#root/bootstrap.js';
import '#css/app.css';

import App from '#login/LoginApp.svelte';

// eslint-disable-next-line no-new
new App({
    target: document.getElementById('loginApp'),
    props: {
        // eslint-disable-next-line no-undef
        errors: loginErrors,
    },
});
