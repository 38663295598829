<script>
    import axios from 'axios';
    import LoadingIndicator from '#components/router/LoadingIndicator.svelte';
    import Router from '#components/router/Router.svelte';
    import { routes } from '#login/routes.js';
    import { theme } from '#login/stores/theme.js';
    import { loginErrors } from '#login/stores/errors.js';
    import { translationsStore } from '#stores/translations/translations.js';
    import { enableSSO, onlySSOLogin } from '#login/stores/settings.js';

    export let themeOptions = {};
    export let errors = {};
    let completelyLoaded = false;

    init();

    async function init() {
        $theme = themeOptions ?? {};
        $loginErrors = errors ?? {};
        // eslint-disable-next-line no-undef
        $enableSSO = singleSignOnEnabled;
        // eslint-disable-next-line no-undef
        $onlySSOLogin = onlySSOEnabled;

        await axios.get('/api/v2/translations').then(response => {
            $translationsStore = response.data.data;
        });

        completelyLoaded = true;
    }
</script>

{#if completelyLoaded}
    <Router {routes} />
{:else}
    <LoadingIndicator />
{/if}
